import Header from './header.js';

const TopSection = () => {
    return (
        <header className="top-section">
            <Header />
            <img id="bannerImg" src="/bannerImage.jpg" alt="Banner Image of Lindsay in Vietnam"/>
        </header>
    );
}

export default TopSection;