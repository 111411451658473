import './App.scss';
import NavigationLinks from './components/navigationLinks.js';
import TopSection from './components/topSection.js';


function App() {
  return (
    <div className="landing">
      <NavigationLinks />
      <TopSection />
    </div>
  );
}

export default App;
