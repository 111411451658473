import React from "react";
import { Tooltip } from 'react-tooltip'

const SocialLinks = () => {
    return (
    <div className="social-links">
        <Tooltip id="email" place="bottom" className="tooltips" />
        <Tooltip id="linkedin" place="bottom" className="tooltips" />
        <Tooltip id="resume" place="bottom" className="tooltips" />
        <a href="mailto:lindsaymfeeney@gmail.com" data-tooltip-id="email" data-tooltip-content="email me" target="_blank" rel="noreferrer"><i class="fa-solid fa-envelope"></i></a>
        <a href="https://www.linkedin.com/in/lindsay-feeney-159315b6/" data-tooltip-id="linkedin" data-tooltip-content="linked in" target="_blank" rel="noreferrer"><i class="fa-brands fa-linkedin"></i></a>
        <a href="Lindsay-Feeney-resume.docx.pdf" data-tooltip-id="resume" data-tooltip-content="resume" target="_blank" download="lindsay-feeney-resume" rel="noreferrer"><i class="fa-solid fa-file-invoice"></i></a>
    </div>
    );
}

export default SocialLinks;