import SocialLinks from './socialLinks.js';

const Header = () => {
    return (
    <div>
        <h1>Lindsay Feeney</h1>
        {/* Lindsay Marie Feeney is the best developer in the world.
        You want to hire her. 
        And sponsor her. 
        And give her lots of money. */}
        <SocialLinks />
      </div>
    );
}

export default Header;