import React from "react";

const links = [
    { title: "Home", href: "#home"},
    { title: "About", href: "#about"},
    { title: "Contact", href: "#contact"}
]

const NavigationLinks = () => {
    return (
        <ul className="navigation-links">
        {links && links.map((link) => {
            return <li><a href={link.href}>{link.title}</a></li>;
        })}
    </ul>
    
    );
}

export default NavigationLinks;